"use strict";

import gallerySlider from "./modules/gallerySlider.js";
import advantagesSlider from "./modules/advantagesSlider.js";
import hamburger from "./modules/hamburger.js";
import headerBg from "./modules/headerBg.js";
import faqCurrent from "./modules/faqCurrent.js";
import showFaq from "./modules/showFaq.js";

try {
    gallerySlider();
} catch (error) {
    
}

try {
    advantagesSlider();
} catch (error) {
    
}

showFaq();
hamburger();
headerBg();
faqCurrent();