const gallerySlider = () => {
  $('.gallery__list').slick({

    infinite: true,
    initialSlide: 1,
    centerMode: true,
    centerPadding: 0,
    variableWidth: true,
    swipeToSlide: true,
    touchMove: true,
    focusOnSelect: true,
    dots: false,
    arrows: false   
    });
};

export default gallerySlider;